export default class Papillons {
    observer

    constructor(observer) {
        this.observer = observer;
        this.init();
    }

    init() {
        const targets = document.querySelectorAll('.papillon');

        targets.forEach(li => {
            this.observer.observe(li);
        })
    }
}