export default class Keypoints {
    observer

    constructor(observer) {
        this.observer = observer;
        this.init();
    }

    init() {
        const targets = document.querySelectorAll('.indications-therapeutiques ul li ');

        targets.forEach(li => {
            this.observer.observe(li);
        })
    }
}