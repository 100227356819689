// here you put all the js you want.
import Keypoints from "./js/keypoints";
import Papillons from "./js/papillons";

var options = {
    rootMargin: '0px',
    threshold: 0.2
}

function callback(entries) {
    entries.filter(el => {
        if(el.isIntersecting) {
            el.target.classList.add('visible');
        }
    })
}

let observer = new IntersectionObserver(callback, options);

const keypoints = new Keypoints(observer);
const papillons = new Papillons(observer);


// Get the button:
let scrollTopButton = document.getElementById("go-to-top");

// When the user scrolls down 20px from the top of the document, show the button
window.onscroll = function() {scrollFunction()};

function scrollFunction() {
    if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
        scrollTopButton.style.display = "block";
    } else {
        scrollTopButton.style.display = "none";
    }
}